import saleNotes from "./sale-notes.vue";
import billingInfo from "./billing-info.vue";
import gpBonus from "./gp-bonus.vue";
import passengerInfo from "./passenger-info.vue";
import financialInfo from "./financial-info.vue";
import SendPaymentForm from "./send-payment-form.vue";
import Attache from "./attached.vue";
import sendticket from "./send-ticket.vue";
import saleHotel from "./sale-hotel.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import store from "@/store/index.js";
// import { forEach } from "core-js/core/array";

export default {
  data: () => ({
    sales: [],
    saleRef: [],
    opened: [],
    allItemsOpen: false,
    allSalesOpen: false,
    showSendPaymentForm: false,
    showSendTicketForm: false,
    // sales_passengers:'',
    userChanges: {},
    rfs: [],
    error: "",
    searchText: "",
    showLoader: false,
    teamList: [],
    previewList: [],
    usersList: [],
    opened: [],
    hotel_no_of_rooms: 0,
    salesList: [],
    hotel: null,
    resInfo: "",
    cabin_class: ["Basic"],
    seat_number: ["ASC"],
    error: "",
    // showLoader: false,
    showTAF: false,
    showRow: false,
    showPnr: [],
    activeIndex: null,
    emails: [],
    sendMail: [],
    externalMailId: "",
    selectSales: null,
    saleNotes: [],
    error: "",
    // showLoader: false,
    showNote: false,
    disableButton: true,
    note: {
      sale_id: "",
      notes: "",
    },
    salepnr: {
      sale_id: "",
      pnr: "",
    },
    passengerData: {
      first_name_pass: "",
      middle_name_pass: "",
    },
    attache: false,
    splitMcoPayment: false, // Checkbox value
    splitMcoArray: [],
    pccsList: [],
    milageprogramsList: [],
    originalSales: {},
    options: ["ADT", "CHD", "INF"],
    selectedPassengerId: null,
    originalSales: [],
    updatedSales: [],
    currencyCode: "",
    modal_sales: false,
    editedSaleIndex: -1,
    result: 0,
    showLiTab: true,
    showSpan: false,
    showFilePreview: false,
    financial_pax: [],
    //  isButtonDisabled: false,
    saveButtonEnabled: true,
    title: "Send Payment Form",
    tempPNRValue: "",
    datePickerFormat: "MMM/DD/YYYY",
    datePickerFormat1: "YYYY-MMM-DD",
    // self_send :false
    send_as_email: false,
    isEmailUnmasked: false,
    isPhoneUnmasked: false,
    isEmailUnmasked1: false,
    isPhoneUnmasked1: false,
    passengerEmailLoadingStates: [],
    passengerPhoneLoadingStates: [],
    isPhoneLoading: false,
    isEmailLoading: false,
    paymentFormOpenTime: false,
    TAdiscount: "0.00",
  }),
  components: {
    Multiselect,
    "billing-info": billingInfo,
    "gp-bonus": gpBonus,
    "passenger-info": passengerInfo,
    "financial-info": financialInfo,
    "sale-hotel": saleHotel,
    SendPaymentForm,
    "send-ticket": sendticket,
    DatePicker,
    Attache,
  },

  watch: {
    sales: {
      deep: true,
      handler(newVal, oldVal) { },
    },
  },
  created() {
    this.refNumber.sales.forEach(() => {
      this.showPnrArray.push(false);
    });
  },
  computed: {
    ttoalnetcost() {
      let total = 0;
      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          ref.financial_informations.financial_pax.forEach((ref1) => {
            // const net_price = ref.financial_informations.net_price;
          });
        });
      });
      return total.toFixed(2);
    },

    totalBT365() {
      let total = 0;
      let total1 = 0;
      let result = 0;

      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          if (ref.issue_program == "Awards") {
            total +=
              this.totalFinancialSellingPrice(ref.financial_informations) -
              this.totalTax(ref.financial_informations) *
              (ref.financial_informations.is_mco ? 1 : 0);
          }
          if (ref.issue_program == "Revenue") {
            total1 +=
              this.totalFinancialSellingPrice(ref.financial_informations) -
              ((this.totalFinancialNetprice1(ref.financial_informations) +
                this.totalFinancialCappedmarkup(ref.financial_informations)) *
                (ref.financial_informations.is_mco ? 1 : 0) +
                this.totalFinancialUpgradeCost(ref.financial_informations) *
                (ref.financial_informations.is_upg_cc ? 1 : 0));
          }
        });
      });

      result = total + total1;
      return result.toFixed(2);
    },

    // totalBT365() {
    //   let total = 0;
    //   let total1 = 0;
    //   let result = 0;
    //   this.sales.forEach(sale => {
    //     sale.sales.forEach(ref => {

    //         if (ref.issue_program == 'Awards') {
    //           total = ((this.totalFinancialSellingPrice(ref.financial_informations)) -
    //           ((this.totalTax(ref.financial_informations)) *
    //             (ref.financial_informations.is_mco ? 1 : 0)))
    //         }
    //         if (ref.issue_program == 'Revenue') {
    //           total1 =  (this.totalFinancialSellingPrice(ref.financial_informations) -
    //           (((this.totalFinancialNetprice1(ref.financial_informations) +
    //             this.totalFinancialCappedmarkup(ref.financial_informations)) *
    //             (ref.financial_informations.is_mco ? 1 : 0) +
    //             (this.totalFinancialUpgradeCost(ref.financial_informations) *
    //               (ref.financial_informations.is_upg_cc
    //                 ? 1 : 0)))))
    //         }

    //     });
    //   });
    //   result = total + total1
    //   return result.toFixed(2);
    // },
    createdBy() {
      if (this.sales && this.sales.created_by_detail) {
        return (
          this.sales.created_by_detail.firstname +
          " " +
          this.sales.created_by_detail.lastname
        );
      }
      return "";
    },
    ticketedBy() {
      http: if (this.sales && this.sales.ticketed_by_detail) {
        return (
          this.sales.ticketed_by_detail.firstname +
          " " +
          this.sales.ticketed_by_detail.lastname
        );
      }
      return "";
    },
    checkedBy() {
      if (this.sales && this.sales.checked_by_detail) {
        return (
          this.sales.checked_by_detail.firstname +
          " " +
          this.sales.checked_by_detail.lastname
        );
      }
      return "";
    },
    approvedBy() {
      if (this.sales && this.sales.approved_by_detail) {
        return (
          this.sales.approved_by_detail.firstname +
          " " +
          this.sales.approved_by_detail.lastname
        );
      }
      return "";
    },
    issalespaymentformPermission() {
      const permission = this.hassalespaymentformPermission();
      return permission;
    },
    issalespaymentPDFPermission() {
      const permission = this.hassalespaymentPDFPermission();
      return permission;
    },

    isrejectPermission() {
      const permission = this.hasrejectPermission();
      return permission;
    },
    isupdatePermission() {
      const permission = this.hasupdatePermission();
      return permission;
    },
    isMarkAsClosedPermission() {
      const permission = this.hasMarkAsClosedPermission();
      return permission;
    },
    ismarkTicketedPermission() {
      const permission = this.hasmarkTicketedPermission();
      return permission;
    },
    isupdateticketnumberPermission() {
      const permission = this.hasupdateticketnumberPermission();
      return permission;
    },
  },

  methods: {
    getPercentageNumber(number) {
      return Math.round(number * 100);
    },
    openPaymentFormTime() {
      let _vm = this;
      _vm.paymentFormOpenTime = !_vm.paymentFormOpenTime;
    },
    downloadPaymentFile() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.axios
        .get("/payment-form-pdf-download/" + _vm.sales[0].sale_reference.id, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const pdfName =
            "payment-form-" + _vm.sales[0].sale_reference.id + ".pdf";
          link.setAttribute("download", pdfName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          store.state.isLoaderShow = false;
        })
        .catch((error) => {
          console.error("Error downloading the PDF", error);
          store.state.isLoaderShow = false;
        });
    },
    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(2);
      const time = timestamp.slice(11, 16);

      return `${month}/${day}/${year} ${time}`;
    },

    togglePassPhoneMaskStatus(passengersIndex) {
      const passenger = this.sales[0].sales_passengers[passengersIndex];
      this.$set(this.passengerPhoneLoadingStates, passengersIndex, true);
      if (passenger.isPhoneUnmasked1) {
        this.$set(passenger, "isPhoneUnmasked1", false);
        this.$set(this.passengerPhoneLoadingStates, passengersIndex, false);
        return;
      }
      if (!this.isPhoneUnmasked1) {
        this.axios
          .get("/sale-view-count")
          .then((response) => {
            if (response.data.data === true) {
              this.$set(
                passenger,
                "isPhoneUnmasked1",
                !passenger.isPhoneUnmasked1
              );
            }
          })
          .catch((error) => { })
          .finally(() => {
            this.$set(this.passengerPhoneLoadingStates, passengersIndex, false);
          });
      }
    },

    togglePassEmailMaskStatus(passengersIndex) {
      const passenger = this.sales[0].sales_passengers[passengersIndex];
      this.$set(this.passengerEmailLoadingStates, passengersIndex, true);
      if (passenger.isEmailUnmasked1) {
        this.$set(passenger, "isEmailUnmasked1", false);
        this.$set(this.passengerEmailLoadingStates, passengersIndex, false);
        return;
      }
      if (!this.isEmailUnmasked1) {
        this.axios
          .get("/sale-view-count")
          .then((response) => {
            if (response.data.data === true) {
              this.$set(
                passenger,
                "isEmailUnmasked1",
                !passenger.isEmailUnmasked1
              );
            }
          })
          .catch((error) => {
            // Handle errors if necessary
          })
          .finally(() => {
            this.$set(this.passengerEmailLoadingStates, passengersIndex, false);
          });
      }
    },

    togglePhoneMaskStatus() {
      if (!this.isPhoneUnmasked) {
        this.isPhoneLoading = true;
        this.axios
          .get("/sale-view-count")
          .then((response) => {
            if (response.data.data === true) {
              this.isPhoneUnmasked = !this.isPhoneUnmasked;
            }
          })
          .catch((error) => { })
          .finally(() => {
            this.isPhoneLoading = false;
          });
      } else {
        this.isPhoneUnmasked = false;
      }
    },

    toggleEmailMaskStatus() {
      if (!this.isEmailUnmasked) {
        this.isEmailLoading = true;
        this.axios
          .get("/sale-view-count")
          .then((response) => {
            if (response.data.data === true) {
              this.isEmailUnmasked = !this.isEmailUnmasked;
            }
          })
          .catch((error) => { })
          .finally(() => {
            this.isEmailLoading = false;
          });
      } else {
        this.isEmailUnmasked = false;
      }
    },
    handleInput(
      net_price,
      k,
      saleIndex,
      paxIndex,
      upgrade_cost,
      capped_markup
    ) {
      this.calculateTKTCost(
        k,
        saleIndex,
        paxIndex,
        net_price,
        upgrade_cost,
        capped_markup
      );
      this.updateSalesProperty(k, "net_price", net_price);
    },
    getFullGdsName(abbreviation) {
      const gdsMapping = {
        A: "Apollo",
        S: "Sabre",
        M: "Amadeus",
        G: "Galileo",
      };
      return gdsMapping[abbreviation] || abbreviation;
    },
    handleUpgradeCostInput(
      upgrade_cost,
      k,
      saleIndex,
      paxIndex,
      net_price,
      capped_markup
    ) {
      this.calculateTKTCost(
        k,
        saleIndex,
        paxIndex,
        net_price,
        upgrade_cost,
        capped_markup
      );
      this.updateSalesProperty(k, "upgrade_cost", upgrade_cost);
    },
    handleCappedMarkupInput(
      capped_markup,
      k,
      saleIndex,
      paxIndex,
      net_price,
      upgrade_cost
    ) {
      this.calculateTKTCost(
        k,
        saleIndex,
        paxIndex,
        net_price,
        upgrade_cost,
        capped_markup
      );
      this.updateSalesProperty(k, "capped_markup", capped_markup);
    },
    cappedInput(
      net_price,
      k,
      saleIndex,
      paxIndex,
      upgrade_cost,
      capped_markup
    ) {
      calculateTKTCost(
        k,
        saleIndex,
        paxIndex,
        net_price,
        $event.target.value || null,
        pax.capped_markup
      );
      updateSalesProperty(k, "upgrade_cost", pax.upgrade_cost);
    },
    openSendPaymentForm() {
      this.showSendPaymentForm = true;
    },
    openSendTicketForm() {
      this.showSendTicketForm = true;
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const next14Months = new Date(
        today.getFullYear(),
        today.getMonth() + 14,
        today.getDate()
      );
      return date < today || date >= next14Months;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayTwelveOClock(date) {
      return date < new Date(new Date().setHours(12, 0, 0, 0));
    },
    maskPhoneNumber(phoneNumber, isUnmasked) {
      if (isUnmasked) {
        return phoneNumber; // Do not mask if isUnmasked is true
      }
      if (phoneNumber && phoneNumber.length >= 4) {
        const maskedPart = phoneNumber.substring(0, 2);
        const remainingDigits = phoneNumber.length - 4;
        const maskedValue = "*".repeat(remainingDigits);
        const lastPart = phoneNumber.substring(phoneNumber.length - 2);
        return `${maskedPart}${maskedValue}${lastPart}`;
      } else {
        return "";
      }
    },
    maskEmail(email, isUnmasked) {
      if (isUnmasked) {
        return email;
      }
      if (email && email.length >= 4) {
        const atIndex = email.indexOf("@");
        if (atIndex > 0) {
          const maskedPart = email.substring(0, 2);
          const domainPart = email.substring(atIndex);
          const domainParts = domainPart.split(".");
          if (domainParts.length > 1) {
            const maskedValue = "*".repeat(domainParts[0].length - 2);
            return `${maskedPart}${maskedValue}${domainPart}`;
          }
        }
      }
      return "";
    },
    maskEmail1(email) {
      if (email && email.length >= 4) {
        const atIndex = email.indexOf("@");
        if (atIndex > 0) {
          const maskedPart = email.substring(0, 2);
          const domainPart = email.substring(atIndex);
          const domainParts = domainPart.split(".");
          if (domainParts.length > 1) {
            const maskedValue = "*".repeat(domainParts[0].length - 2);
            return this.isEmailUnmasked
              ? email
              : `${maskedPart}${maskedValue}${domainPart}`;
          }
        }
      }
      return "";
    },
    hassalespaymentformPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-payment-from-preview";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hassalespaymentPDFPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-payment-from-pdf-download";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasupdateticketnumberPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-update-ticket-number";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasrejectPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-reject-after-payment-received";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasupdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-update-after-payment-received";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasMarkAsClosedPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-reference-mark-closed";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasmarkTicketedPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-reference-mark-ticketed";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    sendSelfTicket(sale_reference_id) {
      let _vm = this;
      let self_send = true;
      this.axios
        .post("/send-ticket", {
          sale_reference_id: sale_reference_id,
          self_send: self_send,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => { });
    },
    sendCustomerTicket(sale_reference_id) {
      let _vm = this;
      let self_send = false;
      this.axios
        .post("/send-ticket", {
          sale_reference_id: sale_reference_id,
          self_send: self_send,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => { });
    },
    isSendButtonDisabled() {
      for (const data of this.sales) {
        for (const sales1 of data.sales) {
          for (const pax of sales1.financial_informations.financial_pax) {
            if (!pax.ticket_nr) {
              return true;
            }
          }
        }
      }
      for (const data of this.sales) {
        for (const sales1 of data.sales) {
          for (const pax of sales1.airline_confirmation_numbers) {
            if (!pax.confirmation_number) {
              return true;
            }
          }
        }
      }
      return false;
    },
    enableSaveButton() {
      this.saveButtonEnabled = true;
    },
    openAttache() {
      this.attache = true;
    },

    showFinancialInfoTab() {
      this.showLiTab = true;
    },
    showPassengerInfoTab() {
      this.showLiTab = false;
    },
    chnageMilageprogramsValue(index, saleIndex, event) {
      const selectedMilageProgramId = event.target.value;
      const selectedMilageProgram = this.milageprogramsList.find((program) => {
        return program.id == selectedMilageProgramId;
      });
      for (
        let i = 0;
        i <
        this.sales[index].sales[saleIndex].financial_informations.financial_pax
          .length;
        i++
      ) {
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "supplier_coefficient",
          selectedMilageProgram.cpm
        );
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "issuing_fee",
          selectedMilageProgram.issuing_fee
        );
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "supplier",
          selectedMilageProgram.id
        );
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "supplier_name",
          selectedMilageProgram.name
        );
        const miles =
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i].miles || 0;
        this.changeMilesValue(index, saleIndex, i, miles);
      }
      this.updateSalesProperty(index, "supplier", selectedMilageProgram.cpm);
    },

    chnagePccsValue(index, saleIndex, event) {
      const selectedPccId = event.target.value;
      const pcc = this.pccsList.find((element) => {
        return element.id == selectedPccId;
      });
      for (
        let i = 0;
        i <
        this.sales[index]["sales"][saleIndex]["financial_informations"]
          .financial_pax.length;
        i++
      ) {
        this.$set(
          this.sales[index]["sales"][saleIndex]["financial_informations"]
            .financial_pax[i],
          "issuing_fee",
          pcc.issuing_fee
        );
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "supplier_name",
          pcc.name
        );
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[i],
          "supplier",
          pcc.id
        );
        this.changePccsValue(
          index,
          saleIndex,
          i,
          this.sales[index]["sales"][saleIndex]["financial_informations"]
            .financial_pax[i].pax || 0
        );
        this.updateSalesProperty(index, "supplier", event.target.value);
      }
    },
    updatesalesPassanger(index) {
      let _vm = this;
      const updatedPassengerData = {
        id: _vm.selectedPassengerId,
        first_name: _vm.passengerData.first_name_pass,
        middle_name: _vm.passengerData.middle_name_pass,
        last_name: _vm.passengerData.last_name_pass,
        type: _vm.passengerData.ptc_pass,
        date_of_birth: _vm.passengerData.date_of_birth,
      };
      _vm.modal_sales = true;
      _vm.axios
        .post("/update-sale-passenger/", {
          sale_passenger: updatedPassengerData,
        })
        .then(() => {
          // _vm.modal_sales = false;
          setTimeout(() => {
            _vm.modal_sales = false;
          }, 100);
          const financialPaxArray =
            _vm.sales[0].sales[0].financial_informations.financial_pax;
          const passengerToUpdate = financialPaxArray.find(
            (pax) => pax.id === _vm.selectedPassengerId
          );
          if (passengerToUpdate) {
            Object.assign(passengerToUpdate.sale_passenger, {
              first_name: _vm.passengerData.first_name_pass,
              middle_name: _vm.passengerData.middle_name_pass,
              last_name: _vm.passengerData.last_name_pass,
              type: _vm.passengerData.ptc_pass,
              date_of_birth: _vm.passengerData.date_of_birth,
            });
          }
          for (let i = 0; i < _vm.sales[0].sales_passengers.length; i++) {
            const passengerToUpdate = _vm.sales[0].sales_passengers[i];
            if (passengerToUpdate.id === _vm.selectedPassengerId) {
              Object.assign(passengerToUpdate, updatedPassengerData);
            }
          }
        })
        .catch(() => {
          // Handle error if needed.
        })
        .finally(function () {
          _vm.$emit("close");
        });
    },
    openModal(pax) {
      this.selectedPassengerId = pax.sale_passenger_id;
      this.modal_sales = true;
      this.passengerData = {
        first_name_pass: pax.sale_passenger.first_name || "",
        middle_name_pass: pax.sale_passenger.middle_name || "",
        last_name_pass: pax.sale_passenger.last_name || "",
        ptc_pass: pax.sale_passenger.type || "",
        date_of_birth: pax.sale_passenger.date_of_birth || "",
      };
      // $("#sale_pass").modal("show");
    },
    closeModal() {
      this.selectedPassengerId = null;
      this.modal_sales = false;
      this.passengerData = {
        first_name_pass: "",
        middle_name_pass: "",
        last_name_pass: "",
        ptc_pass: "",
        date_of_birth: "",
      };
      // $("#sale_pass").modal("hide");
    },

    handleCheckboxChange(index, property, value) {
      const originalValue = this.originalSales[index];
      const currentValue = this.sales[index];
      const isValidValue = this.isNotValidvalue(value);
      if (
        (originalValue === currentValue && isValidValue) ||
        (!originalValue && !isValidValue)
      ) {
        delete this.userChanges[property];
      } else {
        this.$set(this.userChanges, index, {
          ...this.userChanges[index],
          [property]: value,
        });
      }
      this.editedSaleIndex = index;
    },
    disableMCO(index, saleIndex) {
      const financialInformations =
        this.sales[index].sales[saleIndex].financial_informations;
      const totalNetPrice = this.totalFinancialNetprice(financialInformations);
      const totalCappedMarkup = this.totalFinancialCappedmarkup(
        financialInformations
      );
      const totalSellingPrice = this.totalFinancialSellingPrice(
        financialInformations
      );
      const isDisable = totalNetPrice + totalCappedMarkup > totalSellingPrice;
      if (isDisable) {
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations,
          "is_mco",
          false
        );
      }
      return isDisable;
    },
    disableMCOCase4(index, saleIndex) {
      const financialInformations =
        this.sales[index].sales[saleIndex].financial_informations;
      const totalFinancialUpgradeCost = this.totalFinancialUpgradeCost(
        financialInformations
      );
      const isDisable = totalFinancialUpgradeCost === 0;
      if (isDisable) {
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations,
          "is_upg_cc",
          false
        );
      }
      return isDisable;
    },
    disableMCOCase2(index, saleIndex) {
      const financialInformations =
        this.sales[index].sales[saleIndex].financial_informations;
      const totalNetPrice = this.totalFinancialNetprice(financialInformations);
      const totalCappedMarkup = this.totalFinancialCappedmarkup(
        financialInformations
      );
      const totalFinancialUpgradeCost = this.totalFinancialUpgradeCost(
        financialInformations
      );
      const totalSellingPrice = this.totalFinancialSellingPrice(
        financialInformations
      );
      let isDisable = false;
      if (totalFinancialUpgradeCost == 0) {
        isDisable = true;
      }
      const mcoValue = financialInformations.is_mco ? 1 : 0;
      const mcoOnCost = (totalNetPrice + totalCappedMarkup) * mcoValue;
      if (mcoOnCost + totalFinancialUpgradeCost > totalSellingPrice) {
        isDisable = true;
      }
      if (isDisable) {
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations,
          "is_upg_cc",
          false
        );
      }
      return isDisable;
    },
    updateSalesProperty(index, property, value) {
      const originalValue = this.originalSales[index];
      const currentValue = this.sales[index];
      const isValidValue = this.isNotValidvalue(value);
      if (
        (originalValue === currentValue && isValidValue) ||
        (!originalValue && !isValidValue)
      ) {
        delete this.userChanges[property];
      } else {
        this.$set(this.userChanges, index, {
          ...this.userChanges[index],
          [property]: value,
        });
      }

      this.$set(this.sales[index], property, value);
      this.editedSaleIndex = index;
    },

    disableButtonForSale(index) {
      return !this.userChanges[index] || this.editedSaleIndex !== index;
    },
    // disableMCO(index, saleIndex) {
    //   const isDisable = this.sales[index].sales[saleIndex].financial_informations.financial_pax.find(pax => pax.net_price + pax.capped_markup > pax.selling_price)
    //   if (isDisable) {
    //     this.$set(this.sales[index].sales[saleIndex].financial_informations, "is_mco", false);
    //   }
    //   return isDisable
    // },
    disableISUPGCC(index, saleIndex) {
      const financialInformations =
        this.sales[index].sales[saleIndex].financial_informations;
      const totaltax = this.totalTax(financialInformations);
      const totalSellingPrice = this.totalFinancialSellingPrice(
        financialInformations
      );
      const isDisable = totaltax > totalSellingPrice;
      if (isDisable) {
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations,
          "is_mco",
          false
        );
      }
      return isDisable;
    },
    calculateTKTCost(
      index,
      saleIndex,
      paxIndex,
      net_price,
      upgrade_cost,
      capped_markup
    ) {
      const tktcost = (+net_price + +upgrade_cost + +capped_markup).toFixed(2);
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "net_cost",
        tktcost
      );
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "net_price",
        net_price
      );
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "upgrade_cost",
        upgrade_cost
      );
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "capped_markup",
        capped_markup
      );
    },

    isNotValidvalue(value) {
      return value && value.toString().length > 0;
    },
    changeMilesCostValue(index, saleIndex, paxIndex) {
      const mileCost = parseFloat(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
          paxIndex
        ].mile_cost
      );
      const tax = parseFloat(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
          paxIndex
        ].tax
      );
      if (!isNaN(mileCost) && !isNaN(tax)) {
        const netPrice = mileCost + tax;
        this.$set(
          this.sales[index].sales[saleIndex].financial_informations
            .financial_pax[paxIndex],
          "net_cost",
          netPrice
        );
        this.updateSalesProperty(index, "net_cost", netPrice);
      }
    },

    changeMilesValue(index, saleIndex, paxIndex, value) {
      const supplierCoefficient = parseFloat(
        this.sales[index]["sales"][saleIndex]["financial_informations"]
          .financial_pax[paxIndex].supplier_coefficient
      );
      this.$set(
        this.sales[index]["sales"][saleIndex]["financial_informations"]
          .financial_pax[paxIndex],
        "mile_cost",
        supplierCoefficient * value
      );
      this.changeMilesCostValue(index, saleIndex, paxIndex);
      this.updateSalesProperty(index, "miles", supplierCoefficient * value);
    },

    calculateMileCost(index, saleIndex, paxIndex, supplierCoefficient, miles) {
      // supplierCoefficient = parseFloat(supplierCoefficient || 0);
      // miles = parseFloat(miles || 0);
      const mileCost = supplierCoefficient * miles;
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "mile_cost",
        mileCost
      );
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "supplier_coefficient",
        supplierCoefficient
      );
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "miles",
        miles
      );
      this.changeMilesCostValue(index, saleIndex, paxIndex);
      this.updateSalesProperty(index, "miles", miles);
    },

    updateMilesAndTax(index, saleIndex, paxIndex, field, value) {
      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        field,
        value
      );
      const milesValue = parseFloat(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
          paxIndex
        ].mile_cost || 0
      );
      const taxValue = parseFloat(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
          paxIndex
        ].tax || 0
      );
      const mileCost = milesValue + taxValue;

      this.$set(
        this.sales[index].sales[saleIndex].financial_informations.financial_pax[
        paxIndex
        ],
        "net_cost",
        mileCost
      );
      this.updateSalesProperty(index, "net_cost", mileCost);
    },
    changePccsValue(index, saleIndex, paxIndex) {
      const supplierCoefficient =
        this.sales[index]["sales"][saleIndex]["financial_informations"]
          .financial_pax[paxIndex].issuing_fee;
      this.$set(
        this.sales[index]["sales"][saleIndex]["financial_informations"]
          .financial_pax[paxIndex]
      );
      this.updateSalesProperty(index, supplierCoefficient);
    },
    totalNetProfit() {
      let totalAwards = 0;
      let totalRevenue = 0;
      let total = 0;
      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          if (ref.financial_informations) {
            if (ref.issue_program == "Awards") {
              totalAwards +=
                this.totalFinancialSellingPrice(ref.financial_informations) -
                this.totalFinancialMilesCost(ref.financial_informations) -
                this.totalTax(ref.financial_informations) +
                this.totalFinancialIncentive(ref.financial_informations) -
                (this.totalProcessingFee(ref.financial_informations) +
                  (this.totalFinancialSellingPrice(ref.financial_informations) -
                    this.totalTax(ref.financial_informations) *
                    (ref.financial_informations.is_mco ? 1 : 0)) *
                  ref.financial_informations.cc_coefficient);
            }
            if (ref.issue_program == "Revenue") {
              totalRevenue +=
                this.totalFinancialSellingPrice(ref.financial_informations) -
                this.totalFinancialNetprice1(ref.financial_informations) -
                this.totalFinancialUpgradeCost(ref.financial_informations) +
                this.totalDropNet(ref.financial_informations) +
                this.totalFinancialCommission(ref.financial_informations) +
                this.totalFinancialIncentive(ref.financial_informations) -
                (this.totalProcessingFee(ref.financial_informations) +
                  (this.totalFinancialSellingPrice(ref.financial_informations) -
                    ((this.totalFinancialNetprice1(ref.financial_informations) +
                      this.totalFinancialCappedmarkup(
                        ref.financial_informations
                      )) *
                      (ref.financial_informations.is_mco ? 1 : 0) +
                      this.totalFinancialUpgradeCost(
                        ref.financial_informations
                      ) *
                      (ref.financial_informations.is_upg_cc ? 1 : 0))) *
                  ref.financial_informations.cc_coefficient);
            }
          }
        });
      });
      total = totalAwards + totalRevenue;
      return total;
    },
    totalMarkupForAllSales() {
      let totalAwards = 0;
      let totalRevenue = 0;
      let total = 0;
      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          if (ref.financial_informations) {
            if (ref.issue_program == "Awards") {
              totalAwards +=
                this.totalFinancialSellingPrice(ref.financial_informations) -
                this.totalFinancialMilesCost(ref.financial_informations) -
                this.totalTax(ref.financial_informations) +
                this.totalFinancialIncentive(ref.financial_informations);
            }
            if (ref.issue_program == "Revenue") {
              totalRevenue +=
                this.totalFinancialSellingPrice(ref.financial_informations) -
                this.totalFinancialNetprice1(ref.financial_informations) -
                this.totalFinancialUpgradeCost(ref.financial_informations) +
                this.totalDropNet(ref.financial_informations) +
                this.totalFinancialCommission(ref.financial_informations) +
                this.totalFinancialIncentive(ref.financial_informations);
            }
          }
        });
      });
      total = totalAwards + totalRevenue;
      return total;
    },
    updatesales(index) {
      let _vm = this;
      const totalSellingPrice = this.totalBT365;
      let totalTax = 0;
      let totalFinancialCappedmarkup = 0;

      let saleArray = [];
      // let totalNetProfit = this.totalNetProfit;
      this.sales[index].sale_reference.net_profit = this.totalNetProfit();
      this.sales[index].sales.forEach((element) => {
        totalTax += this.totalTax(element.financial_informations);
        totalFinancialCappedmarkup +=
          this.totalFinancialCappedmarkup(element.financial_informations) +
          this.totalFinancialNetprice1(element.financial_informations);

        if (element.issue_program === "Awards") {
          let processing_fee =
            this.totalProcessingFee(element.financial_informations) +
            (this.totalFinancialSellingPrice(element.financial_informations) -
              this.totalTax(element.financial_informations) *
              (element.financial_informations.is_mco ? 1 : 0)) *
            element.financial_informations.cc_coefficient;
          element.financial_informations.processing_fee = processing_fee;
        }
        if (element.issue_program === "Revenue") {
          let processing_fee =
            this.totalProcessingFee(element.financial_informations) +
            (this.totalFinancialSellingPrice(element.financial_informations) -
              ((this.totalFinancialNetprice1(element.financial_informations) +
                this.totalFinancialCappedmarkup(
                  element.financial_informations
                )) *
                (element.financial_informations.is_mco ? 1 : 0) +
                this.totalFinancialUpgradeCost(element.financial_informations) *
                (element.financial_informations.is_upg_cc ? 1 : 0))) *
            element.financial_informations.cc_coefficient;
          element.financial_informations.processing_fee = processing_fee;
        }
        if (element.issue_program === "Awards") {
          element.financial_informations.credit_card_fee = (
            (this.totalFinancialSellingPrice(element.financial_informations) -
              this.totalTax(element.financial_informations) *
              (element.financial_informations.is_mco ? 1 : 0)) *
            element.financial_informations.cc_coefficient
          ).toFixed(2);
        }
        if (element.issue_program === "Revenue") {
          element.financial_informations.credit_card_fee =
            (this.totalFinancialSellingPrice(element.financial_informations) -
              ((this.totalFinancialNetprice1(element.financial_informations) +
                this.totalFinancialCappedmarkup(
                  element.financial_informations
                )) *
                (element.financial_informations.is_mco ? 1 : 0) +
                this.totalFinancialUpgradeCost(element.financial_informations) *
                (element.financial_informations.is_upg_cc ? 1 : 0))) *
            element.financial_informations.cc_coefficient;
        }
        if (element.issue_program === "Awards") {
          element.financial_informations.gross_profit =
            this.totalFinancialSellingPrice(element.financial_informations) -
            this.totalFinancialMilesCost(element.financial_informations) -
            this.totalTax(element.financial_informations) +
            this.totalFinancialIncentive(element.financial_informations);
        }
        if (element.issue_program === "Revenue") {
          element.financial_informations.gross_profit =
            this.totalFinancialSellingPrice(element.financial_informations) -
            this.totalFinancialNetprice1(element.financial_informations) -
            this.totalFinancialUpgradeCost(element.financial_informations) +
            this.totalDropNet(element.financial_informations) +
            this.totalFinancialCommission(element.financial_informations) +
            this.totalFinancialIncentive(element.financial_informations);
        }
        if (element.issue_program == "Awards") {
          let agents_profit =
            this.totalFinancialSellingPrice(element.financial_informations) -
            this.totalFinancialMilesCost(element.financial_informations) -
            this.totalTax(element.financial_informations) +
            this.totalFinancialIncentive(element.financial_informations) -
            (this.totalProcessingFee(element.financial_informations) +
              (this.totalFinancialSellingPrice(element.financial_informations) -
                this.totalTax(element.financial_informations) *
                (element.financial_informations.is_mco ? 1 : 0)) *
              element.financial_informations.cc_coefficient);
          element.financial_informations.agents_profit = agents_profit;
        }
        if (element.issue_program == "Revenue") {
          let agents_profit =
            this.totalFinancialSellingPrice(element.financial_informations) -
            this.totalFinancialNetprice1(element.financial_informations) -
            this.totalFinancialUpgradeCost(element.financial_informations) +
            this.totalDropNet(element.financial_informations) +
            this.totalFinancialCommission(element.financial_informations) +
            this.totalFinancialIncentive(element.financial_informations) -
            (this.totalProcessingFee(element.financial_informations) +
              (this.totalFinancialSellingPrice(element.financial_informations) -
                ((this.totalFinancialNetprice1(element.financial_informations) +
                  this.totalFinancialCappedmarkup(
                    element.financial_informations
                  )) *
                  (element.financial_informations.is_mco ? 1 : 0) +
                  this.totalFinancialUpgradeCost(
                    element.financial_informations
                  ) *
                  (element.financial_informations.is_upg_cc ? 1 : 0))) *
              element.financial_informations.cc_coefficient);
          element.financial_informations.agents_profit = agents_profit;
        }
        if (element.issue_program == "Awards") {
          element.financial_informations.company_amount =
            this.totalFinancialSellingPrice(element.financial_informations) -
            this.totalTax(element.financial_informations) *
            (element.financial_informations.is_mco ? 1 : 0);
        }
        if (element.issue_program == "Awards") {
          element.financial_informations.airline_amount =
            this.totalTax(element.financial_informations) *
            (element.financial_informations.is_mco ? 1 : 0);
        }
        if (element.issue_program == "Revenue") {
          element.financial_informations.company_amount =
            this.totalFinancialSellingPrice(element.financial_informations) -
            ((this.totalFinancialNetprice1(element.financial_informations) +
              this.totalFinancialCappedmarkup(element.financial_informations)) *
              (element.financial_informations.is_mco ? 1 : 0) +
              this.totalFinancialUpgradeCost(element.financial_informations) *
              (element.financial_informations.is_upg_cc ? 1 : 0));
        }
        if (element.issue_program == "Revenue") {
          element.financial_informations.airline_amount =
            (this.totalFinancialNetprice1(element.financial_informations) +
              this.totalFinancialCappedmarkup(element.financial_informations)) *
            (element.financial_informations.is_mco ? 1 : 0) +
            this.totalFinancialUpgradeCost(element.financial_informations) *
            (element.financial_informations.is_upg_cc ? 1 : 0);
        }
        if (element.financial_informations.is_mco) {
          if (element.issue_program == "Awards") {
            saleArray.push({
              id: element.id,
              name: element.planting_carrier,
              code: element.carrier,
              amount: this.totalTax(element.financial_informations),
            });
          }
          if (element.issue_program == "Revenue") {
            saleArray.push({
              id: element.id,
              name: element.planting_carrier,
              code: element.carrier,
              amount:
                this.totalFinancialCappedmarkup(
                  element.financial_informations
                ) +
                this.totalFinancialNetprice1(element.financial_informations),
            });
          }
        } else {
          if (element.issue_program == "Awards") {
            saleArray.push({
              id: element.id,
              name: element.planting_carrier,
              code: element.carrier,
              amount: 0,
            });
          }
          if (element.issue_program == "Revenue") {
            saleArray.push({
              id: element.id,
              name: element.planting_carrier,
              code: element.carrier,
              amount: 0,
            });
          }
        }
      });

      let data = {
        sales: this.sales[index],
      };
      _vm.disableButton = true;
      _vm.axios
        .post("/sales/" + _vm.$route.params.id, {
          ...data,

          _method: "PATCH",
        })
        .then((response) => {
          window.location.reload();
          if (_vm.$route.params.id) {
            _vm.toggle(_vm.$route.params.id);

            response.data.data.forEach((element) => {
              element.sales.forEach((sale) => {
                if (sale.id == _vm.$route.params.id) {
                  _vm.toggle(sale.id);
                  _vm.toggle(sale.container_id);
                  _vm.toggle(sale.sale_reference_id);
                }
              });
            });
          }
        })
        .catch(() => { })
        .finally(() => {
          _vm.disableButton = true;
          _vm.userChanges = {};
          _vm.editedSaleIndex = -1;
        });
    },

    onTableChange(updatedData) {
      this.updatedSales = JSON.parse(JSON.stringify(updatedData));
    },
    sendSelfPaymentForm(sale_reference_id) {
      let _vm = this;
      let self_send = true;
      _vm.axios
        .post("/send-payment-form", {
          sale_reference_id: sale_reference_id,
          self_send: self_send,
        })
        .then(() => {
          // _vm.getSales();
          if (_vm.$route.params.id) {
            _vm.toggle(_vm.$route.params.id);

            response.data.data.forEach((element) => {
              element.sales.forEach((sale) => {
                if (sale.id == _vm.$route.params.id) {
                  _vm.toggle(sale.id);
                  _vm.toggle(sale.container_id);
                  _vm.toggle(sale.sale_reference_id);
                }
              });
            });
          }
        })

        .catch(() => { })
        .finally(() => {
          _vm.userChanges = {};
        });
    },
    sendCustomerPaymentForm(refNumber) {
      let _vm = this;
      let self_send = false;
      _vm.axios
        .post("/send-payment-form", {
          sale_reference_id: sale_reference_id,
          self_send: self_send,
        })
        .then(() => {
          // _vm.getSales();
          if (_vm.$route.params.id) {
            _vm.toggle(_vm.$route.params.id);

            response.data.data.forEach((element) => {
              element.sales.forEach((sale) => {
                if (sale.id == _vm.$route.params.id) {
                  _vm.toggle(sale.id);
                  _vm.toggle(sale.container_id);
                  _vm.toggle(sale.sale_reference_id);
                }
              });
            });
          }
        })

        .catch(() => { })
        .finally(() => {
          _vm.userChanges = {};
        });
    },
    markTickted(id) {
      let _vm = this;
      _vm.axios
        .post(`/sales-reference-ticketed/${id}`)
        .then((response) => {
          // _vm.saleRef = response.data.data;
          window.location.reload();
          // _vm.$set(_vm.refNumber, 'sale_reference', { status: 'Ticketed' });
        })
        .catch((error) => { });
    },
    markClosed(id) {
      let _vm = this;
      _vm.axios
        .post(`/sales-reference-closed/${id}`)
        .then((response) => {
          // _vm.saleRef = response.data.data;
          window.location.reload();
          // _vm.$set(_vm.refNumber, 'sale_reference', { status: 'Ticketed' });
        })
        .catch((error) => { });
    },
    rejectsales(rfNumber) {
      let _vm = this;
      _vm.$dialog
        .confirm(`Are you sure you want to delete this sales?<br>` + rfNumber)
        .then(function () {
          _vm.axios
            .delete("/sales/" + rfNumber)
            .then(function () {
              window.location.href = "/sales";
            })
            .catch(function (error) { });
        })
        .catch(function (error) { });
    },

    addNote() {
      let _vm = this;
      const sr_number = this.sales[0].sale_reference.sale_reference_id;
      this.note["sale_container_id"] = this.sales[0].container_number;
      this.axios
        .post("/sale-container-notes", { ...this.note, sr_number })
        .then(function () {
          _vm.showNote = false;
          _vm.note.send_as_email = false;
          _vm.note.notes = "";
          _vm.getNotes();
        })
        .catch(function () { });
    },
    openModalPNR(k, saleIndex) {
      this.selectedSaleIndex = saleIndex;
      // this.showPnr = true;
      this.$set(this.showPnr, saleIndex, true);
      this.tempPNRValue = this.sales[0].sales[saleIndex].pnr;
    },

    addPNR(outerIndex, innerIndex) {
      const salesData = this.sales;
      if (
        !salesData ||
        !salesData[outerIndex] ||
        !salesData[outerIndex].sales ||
        !salesData[outerIndex].sales[innerIndex] ||
        typeof salesData[outerIndex].sales[innerIndex].pnr === "undefined"
      ) {
        return;
      }

      const saleId = salesData[outerIndex].sales[innerIndex].id;
      const newPNRValue = this.tempPNRValue;

      this.axios
        .post("/add-pnr", {
          sale_id: saleId,
          pnr: newPNRValue,
        })
        .then(() => {
          salesData[outerIndex].sales[innerIndex].pnr = newPNRValue;
          this.$set(this.showPnr, innerIndex, false);
          this.tempPNRValue = newPNRValue;
        })
        .catch(() => { });
    },
    closeModalPNR(k, saleIndex) {
      this.$set(this.showPnr, saleIndex, false);
    },
    limitAndConvert(outerIndex, innerIndex) {
      let _vm = this;
      _vm.tempPNRValue = _vm.tempPNRValue.substring(0, 6).toUpperCase();
    },
    getNotes() {
      let _vm = this;
      _vm.showLoader = true;
      this.axios
        .get(
          "/sale-container-notes?sale_container_id=" +
          this.sales[0].container_number
        )
        .then(function (response) {
          _vm.showLoader = false;
          _vm.saleNotes = response.data.data;
        })
        .catch(function () { });
    },
    validate() {
      let _vm = this;
      _vm.showSendPaymentForm = false;
      _vm.showSendTicketForm = false;
      _vm.hotel["sale_id"] = _vm.$route.params.id;
      _vm.financialInfo["sale_id"] = _vm.$route.params.id;

      for (let index = 0; index < _vm.hotel.hotel_guests.length; index++) {
        if (
          this.hotel.hotel_guests[index]["first_name"] !== "" &&
          this.hotel.hotel_guests[index]["last_name"] !== ""
        ) {
          _vm.hotel.hotel_guests[index]["hotel_id"] = _vm.hotel.id;
        } else {
          delete _vm.hotel.hotel_guests[index];
        }
      }
      _vm.sales.cabin_class = _vm.cabin_class.join();
      //_vm.sales.seat_number = _vm.seat_number.join();

      _vm.sales.hotels = _vm.hotel;
      _vm.sales.financial_informations = _vm.financialInfo;

      _vm.financialInfo.financial_pax.forEach((element) => {
        element.selling_price =
          element.selling_price == null ? "0" : pax.selling_price;
        element.net_price = element.net_price == null ? "0" : pax.net_price;
        element.capped_markup =
          element.capped_markup == null ? "0" : pax.capped_markup;
        element.drop_net = element.drop_net == null ? "0" : pax.drop_net;
        element.incentive = element.incentive == null ? "0" : pax.incentive;
        element.service_fee =
          element.service_fee == null ? "0" : pax.service_fee;
        element.issuing_fee =
          element.issuing_fee == null ? "0" : pax.issuing_fee;
      });

      _vm.sales.financial_informations = _vm.financialInfo;
      this.axios
        .post("/sales/" + this.$route.params.id, {
          ...this.sales,
          _method: "PATCH",
        })
        .then(function () {
          _vm.getSales();
        })
        .catch(function () { });
    },

    toggleRow(index) {
      if (this.showRow && this.activeIndex === index) {
        this.showRow = false;
        this.activeIndex = null;
      } else {
        this.showRow = true;
        this.activeIndex = index;
      }
    },
    getButtonClass(index) {
      if (this.showRow && this.activeIndex === index) {
        return "btn-danger";
      } else {
        return "btn-success";
      }
    },
    deleteRecord() {
      let _vm = this;
      this.$dialog
        .confirm("Are you sure you want to delete this record?")
        .then(function () {
          _vm.axios
            .delete("/sales/" + _vm.$route.params.id)
            .then(function () {
              _vm.$router.push("/sales");
            })
            .catch(function (error) { });
        })
        .catch(function (error) { });
    },
    getSales() {
      let _vm = this;
      _vm.isLoading = true;
      _vm.showLoader = true;
      this.axios
        .get("/sales/" + this.$route.params.id)
        .then(function (response) {
          _vm.showLoader = false;
          let data = JSON.parse(atob(response.data.data));
          _vm.sales = data;
          console.log(_vm.sales);

          _vm.TAdiscount =
            _vm.sales[0].travel_assist.ta_gold_discount_percentage;

          _vm.originalSales = JSON.parse(JSON.stringify(data));
          _vm.emails = _vm.sales.sale_notification_to
            ? _vm.sales.sale_notification_to.split(",")
            : [];
          _vm.getNotes();
          _vm.currencyCode = _vm.sales[0].sales[0].currency;
          if (_vm.currencyCode === "eur") {
            _vm.currencyCode = "€";
          } else if (_vm.currencyCode === "gbp") {
            _vm.currencyCode = "£";
          } else if (_vm.currencyCode === "usd") {
            _vm.currencyCode = "$";
          } else {
            _vm.currencyCode = "C$";
          }
          if (_vm.$route.params.id) {
            // _vm.toggle(_vm.$route.params.id);
            data.forEach((element) => {
              element.sales.forEach((sale) => {
                sale["issuing_fee"] = parseInt(
                  parseFloat(
                    sale["financial_informations"].financial_pax[0].supplier
                  )
                );
                sale["cpm"] = parseFloat(
                  sale["financial_informations"].financial_pax[0].supplier
                );
                if (sale.id == _vm.$route.params.id) {
                  _vm.toggle(sale.id);
                  _vm.toggle(sale.container_id);
                  _vm.toggle(sale.sale_reference_id);
                }
              });
            });
          }
          // for the title
          let customTitle = data[0].sale_reference.id;
          document.title = customTitle;
          _vm.$route.meta = { title: customTitle, customTitle: true };
          if (_vm.sales.financial_informations !== null) {
            _vm.financialInfo = _vm.sales.financial_informations;
          }
          if (_vm.sales.hotels !== null) {
            _vm.hotel = _vm.sales.hotels;
            if (
              !(_vm.hotel.hotel_guests && _vm.hotel.hotel_guests.length > 0)
            ) {
              _vm.hotel["hotel_guests"] = [
                {
                  hotel_id: 0,
                  first_name: "",
                  last_name: "",
                  guest_type: "Adult",
                },
              ];
            }
          } else {
            _vm.sales.hotels = { hotel_guests: [] };
          }
          _vm.resInfo = _vm.sales.reservation_info.split("\n");
          if (_vm.sales.cabin_class && _vm.sales.cabin_class !== "") {
            _vm.cabin_class = _vm.sales.cabin_class.split(",");
          } else {
            for (let index = 0; index < _vm.resInfo.length; index++) {
              _vm.cabin_class[index] = "Basic";
            }
          }
          _vm.isLoading = false;
        })
        .catch(function () {
          _vm.isLoading = false;
        });
    },
    cabinClassData(e, index) {
      this.cabin_class[index] = e.target.value;
    },
    setSeatNo(e, index) {
      this.seat_number[index] = e.target.value;
    },

    checkEmail(e) {
      let index = this.sendMail.indexOf(e.target.value);
      if (e.target.checked) {
        if (!(index > 0)) this.sendMail.push(e.target.value);
      } else {
        this.sendMail.splice(index, 1);
      }
    },
    sendTAF() {
      if (this.externalMailId !== "") {
        if (!this.sendMail.includes(this.externalMailId)) {
          this.sendMail.push(this.externalMailId);
        }
      }
      let emailIds = this.sendMail.join();
      if (!(emailIds && emailIds.length > 0)) {
        this.$dialog.alert("Please select atleast one email address.");
      }
      let _vm = this;
      this.axios
        .post("/send-email/" + this.$route.params.id, {
          email_ids: emailIds,
        })
        .then(function () {
          _vm.showTAF = false;
          _vm.$emit("close");
        })
        .catch(function (error) { });
    },
    ////////////////////////////////

    // start sales details
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    toggle1(number) {
      const index = this.opened.indexOf(number);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [number];
      }
    },
    toggleAllItems() {
      this.allItemsOpen = !this.allItemsOpen;
      if (this.allItemsOpen) {
        this.opened = [];
        this.sales.forEach((refNumber) => {
          this.opened.push(refNumber.sale_reference.number);
          this.opened.push(refNumber.container_number);
          refNumber.sales.forEach((sale) => {
            this.opened.push(sale.id);
          });
        });
      } else {
        this.opened = [];
      }
    },
    increment() {
      this.hotel_no_of_rooms++;
    },
    decrement() {
      this.hotel_no_of_rooms--;
    },
    // end sales details

    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () { });
    },
    getpaymentformPreview() {
      const _vm = this;
      _vm.showFilePreview = true;
      this.axios
        .get("/payment-form-preview", _vm.previewList)
        .then(function (response) {
          _vm.previewList = response.data.data;
        })
        .catch(function () { });
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () { });
    },
    getExpandedItems() {
      return (items) => {
        return items.filter((item) => this.opened.includes(item.id)) || 0;
      };
    },
    totalSellingPrice(hotels) {
      let sum = 0;
      const hotelGuests = hotels.hotel_guests;
      for (let k = 0; k < hotelGuests.length; k++) {
        const sellingPrice = parseFloat(hotelGuests[k].selling_price) || 0;
        sum += sellingPrice;
      }
      return sum;
    },
    totalNetPrice(hotels) {
      let sum = 0;
      const hotelGuests = hotels.hotel_guests;
      for (let k = 0; k < hotelGuests.length; k++) {
        const profit = parseFloat(hotelGuests[k].net_price) || 0;
        sum += profit;
      }
      return sum;
    },
    totalCommission(hotels) {
      let sum = 0;
      const hotelGuests = hotels.hotel_guests;
      for (let k = 0; k < hotelGuests.length; k++) {
        const profit = parseFloat(hotelGuests[k].commission) || 0;
        sum += profit;
      }
      return sum;
    },
    totalFinancialSellingPrice(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const sellingprice = parseFloat(financialpax[k].selling_price) || 0;
        sum += sellingprice;
      }
      return sum;
    },
    totalFinancialCommission(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].commission) || 0;

        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialBaseTax(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].base_tax) || 0;

        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialUpgradeCost(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].upgrade_cost) || 0;

        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialNetCost(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].net_cost) || 0;

        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialNetprice(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].net_price) || 0;

        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialNetprice1(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const netprice = parseFloat(financialpax[k].net_price) || 0;

        sum += netprice;
      }
      return sum;
    },

    totalFinancialCappedmarkup(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const cappedMarkupPrice =
          parseFloat(financialpax[k].capped_markup) || 0;
        sum += cappedMarkupPrice;
      }
      return sum;
    },

    totalFinancialMilesCost(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesCostPrice = parseFloat(financialpax[k].mile_cost) || 0;
        sum += milesCostPrice;
      }
      return sum;
    },
    totalFinancialMiles(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const milesPrice = parseFloat(financialpax[k].miles) || 0;
        sum += milesPrice;
      }
      return sum;
    },
    totalTax(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const taxPrice = parseFloat(financialpax[k].tax) || 0;
        sum += taxPrice;
      }
      return sum;
    },
    totalFinancialBasePrice(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const baseprice = parseFloat(financialpax[k].base_price) || 0;

        sum += baseprice;
      }
      return sum;
    },
    totalFinancialIncentive(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const incentivePrice = parseFloat(financialpax[k].incentive) || 0;
        sum += incentivePrice;
      }
      return sum;
    },
    totalFinancialServicefee(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const serviceFeePrice = parseFloat(financialpax[k].service_fee) || 0;
        sum += serviceFeePrice;
      }
      return sum;
    },
    totalFinancialIssuingfee(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const issuingFeePrice = parseFloat(financialpax[k].issuing_fee) || 0;
        sum += issuingFeePrice;
      }
      return sum;
    },

    totalDropNet(financial_informations) {
      let sum = 0;
      const financialpax = financial_informations.financial_pax;
      for (let k = 0; k < financialpax.length; k++) {
        const dropNetPrice = parseFloat(financialpax[k].drop_net) || 0;
        sum += dropNetPrice;
      }
      return sum;
    },
    totalProcessingFee(financial_informations) {
      return (
        this.totalFinancialServicefee(financial_informations) +
        this.totalFinancialIssuingfee(financial_informations)
      );
    },

    totalMarkup(financial_informations) {
      return (
        this.totalFinancialSellingPrice(financial_informations) -
        this.totalFinancialMilesCost(financial_informations) -
        this.totalTax(financial_informations)
      );
    },

    totalAwardPRofit(financial_informations) {
      // let sum = 0;
      // let total1 = 0;
      let totalProfit = 0;
      let total =
        this.totalFinancialSellingPrice(financial_informations) -
        this.totalFinancialNetprice(financial_informations) -
        this.totalFinancialServicefee(financial_informations) -
        this.totalFinancialIssuingfee(financial_informations) +
        this.totalFinancialIncentive(financial_informations);
      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          if (ref.issue_program == "Awards") {
            let currentTotal = 0;
            if (ref.financial_informations.is_mco) {
              currentTotal =
                (this.totalTax(financial_informations) -
                  this.totalFinancialSellingPrice(financial_informations)) *
                this.sales[0].billing_informations.billing_methods[0]
                  ?.card_fee || 0;
            } else {
              currentTotal =
                this.totalFinancialSellingPrice(financial_informations) *
                this.sales[0].billing_informations.billing_methods[0]
                  ?.card_fee || 0;
            }
            totalProfit += currentTotal;
          }
        });
      });
      return total - Math.abs(totalProfit);
    },
    totalrevenuPRofit(financial_informations) {
      // let total1 = 0;
      let totalProfit = 0;
      let total =
        this.totalFinancialSellingPrice(financial_informations) -
        this.totalFinancialNetprice(financial_informations) -
        this.totalFinancialServicefee(financial_informations) -
        this.totalFinancialIssuingfee(financial_informations) +
        this.totalDropNet(financial_informations) +
        this.totalFinancialIncentive(financial_informations);
      this.sales.forEach((sale) => {
        sale.sales.forEach((ref) => {
          if (ref.issue_program == "Revenue") {
            let currentTotal = 0;
            if (ref.financial_informations.is_mco) {
              currentTotal =
                (this.totalFinancialSellingPrice(financial_informations) -
                  this.totalFinancialNetprice1(financial_informations)) *
                this.sales[0].billing_informations.billing_methods[0]
                  ?.card_fee || 0;
            } else {
              currentTotal =
                this.totalFinancialSellingPrice(financial_informations) *
                this.sales[0].billing_informations.billing_methods[0]
                  ?.card_fee || 0;
            }
            totalProfit += currentTotal;
          }
        });
      });
      return total - Math.abs(totalProfit);
    },
    getPccs() {
      let _vm = this;
      this.axios
        .get("/quotation-pcc")
        .then(function (response) {
          _vm.pccsList = response.data.data;
        })
        .catch(function () { });
    },
    getMilagePrograms() {
      let _vm = this;
      this.axios
        .get("/quotation-milage-program")
        .then(function (response) {
          _vm.milageprogramsList = response.data.data;
        })
        .catch(function () { });
    },
    getDiscountOnTravelAssist(rfNumber, discount) {
      let _vm = this;
      let _msg = `Are you sure you want to apply a ${discount * 100
        }% Discount?`;

      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          this.axios
            .get("/add-discount-on-travel-assist/" + rfNumber + "/" + discount)
            .then(function (response) {
              _vm.sales[0].travel_assist = response.data.data;
              _vm.sales[0].sale_reference.ta_gold_discount_percentage = _vm.sales[0].travel_assist.ta_gold_discount_percentage;
              _vm.sales[0].sale_reference.ta_silver_discount_percentage = _vm.sales[0].travel_assist.ta_silver_discount_percentage;
              _vm.TAdiscount = _vm.sales[0].travel_assist.ta_gold_discount_percentage;
              store.state.isLoaderShow = false;
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function () {
          if (_vm.sales[0].travel_assist.ta_gold_discount_percentage) {
            _vm.TAdiscount =
              _vm.sales[0].travel_assist.ta_gold_discount_percentage;
          } else {
            _vm.TAdiscount = "";
          }
        });
    },
    selectInputText(inputElement) {
      inputElement.select(); // Select all text in the input field
    },
    salesRefTip(rfNumber, status, k) {
      let _vm = this;
      store.state.isLoaderShow = true;
      let _msg = `Are you sure you want to ${status} ? `;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          _vm.axios
            .get(`/change-tip-status/` + rfNumber + "/" + status)
            .then(function (response) {
              _vm.sales[k].sale_reference.tip_status = status;
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () { });
    },
    travelAssistStatus(rfNumber, status, k) {
      let _vm = this;
      store.state.isLoaderShow = true;
      let _msg = `Are you sure you want to ${status} ? `;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          _vm.axios
            .get(`/change-travel-assist-status/` + rfNumber + "/" + status)
            .then(function (response) {
              _vm.sales[k].travel_assist.ta_status = status;
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () { });
    },
  },
  mounted() {
    this.originalSales = JSON.parse(JSON.stringify(this.sales));
    this.salesInitialValues = { ...this.sales };
    this.getSales();
    this.showDetails = new Array(this.sales.length).fill(false);
    this.getTeam();
    this.getUserList();
    this.getExpandedItems();
    this.getPccs();
    this.getMilagePrograms();
  },
};
