import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      SPF: {
        emails: [],
        phones: [],
        subject: "",
        body: "",
        self_send: false,
      },
      emailList: [],
      allEmails: [],
      allPhones: [],
      selectedPhones: [],
      loading: false,
      isSelfCheckedPhone: false,
      checkboxdisable: true,
    };
  },
  props: {
    sendPaymentForm: Boolean,
    sales: Array,
    showSendPaymentForm: Boolean,
    getSales: {
      type: Function,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    "SPF.self_send": {
      handler: "updateEmailList",
      immediate: true,
      deep: true,
    },
    isSelfCheckedPhone(newVal) {
      if (!newVal) {
        this.selectedPhones = [];
        this.error = "";
      } else {
        if (this.maskedPhonesList.length > 0) {
          // this.selectedPhones = [this.maskedPhonesList[0]];
        }
        this.validateSelectedPhones();
      }
    },
    selectedPhones: {
      handler(newVal, oldVal) {
        if (newVal.length === 1) {
          this.isSelfCheckedPhone = true;
        }

        if (newVal.length === 0 && oldVal.length > 1) {
          return;
        }
        if (newVal.length === 0 && oldVal.length === 1) {
          this.isSelfCheckedPhone = false;
          return;
        }
        this.validateSelectedPhones();
      },
      deep: true,
    },
  },

  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    maskedPhonesList() {
      return this.allPhones.map((phone) => {
        const visibleChars = 2;
        const hiddenChars = phone.value.length - visibleChars - 3;
        const maskedValue =
          phone.value.substring(0, visibleChars) +
          "*".repeat(hiddenChars) +
          phone.value.substring(phone.value.length - 3);
        return {
          value: phone.value,
          maskedValue: maskedValue,
        };
      });
    },
  },
  methods: {
    validateSelectedPhones() {
      if (this.isSelfCheckedPhone && this.selectedPhones.length === 0) {
        this.error = "Please select a phone number.";
      } else {
        this.error = "";
      }
    },
    customLabel(option) {
      const [username, domain] = option.split("@");
      const maskedUsername = `${username.slice(0, 2)}${"*".repeat(
        username.length - 2
      )}${username.slice(-1)}`;
      return `${maskedUsername}@${domain}`;
    },

    validatePage() {
      this.loading = true;
      let _vm = this;
      const sale_reference_id = this.sales[0].sale_reference.id;
      _vm.SPF.selectedPhones = this.selectedPhones.map((phone) => phone.value);
      if (this.SPF.id > 0) {
        this.axios
          .post("/send-payment-form", {
            ...this.SPF,
            sale_reference_id,
            // _method: "PATCH",
          })
          .then(function () {
            _vm.loading = false;
            _vm.showSendPaymentForm = false;
            location.reload();
            _vm.getSales();
            _vm.$emit("updated");
          })
          .catch(function (error) {});
      } else {
        this.axios
          .post("/send-payment-form", {
            ...this.SPF,
            sale_reference_id,
          })
          .then(function () {
            _vm.loading = false;
            _vm.showSendPaymentForm = false;
            location.reload();
            _vm.getSales();
            _vm.$emit("updated");
          })
          .catch(function (error) {});
      }
    },

    updateEmailList() {
      const userIndex = this.allEmails.indexOf(this.user.email);

      if (this.SPF.self_send) {
        if (userIndex === -1) {
          this.allEmails.push(this.user.email);
        }

        if (!this.SPF.selectedEmails) {
          this.$set(this.SPF, "selectedEmails", []);
        }

        if (this.SPF.selectedEmails.indexOf(this.user.email) === -1) {
          this.SPF.selectedEmails.push(this.user.email);
        }
      } else {
        if (userIndex !== -1) {
          this.$delete(this.allEmails, userIndex);
        }

        if (this.SPF.selectedEmails) {
          const userSelectedIndex = this.SPF.selectedEmails.indexOf(
            this.user.email
          );

          if (userSelectedIndex !== -1) {
            this.$delete(this.SPF.selectedEmails, userSelectedIndex);
          }
        }
      }
    },

    getDetail() {
      let _vm = this;
      const sale_reference_id = _vm.sales[0].sale_reference.id;
      this.axios
        .post("/send-payment-form-preview/" + sale_reference_id)
        .then(function (response) {
          _vm.allEmails = response.data.data.emails.map((email) => `${email}`);

          // _vm.allPhones = response.data.data.phones.map((phone) => `${phone}`);

          _vm.allPhones = response.data.data.phones.map((phone) => ({
            value: phone,
            maskedValue:
              phone.substring(0, 2) +
              "*".repeat(phone.length - 5) +
              phone.substring(phone.length - 3),
          }));

          console.log("_vm.allPhones", _vm.allPhones);
          _vm.isSelfCheckedPhone =
            _vm.allPhones !== null && _vm.allPhones.length > 0;

          if (_vm.SPF.self_send) {
            if (!_vm.allEmails.includes(_vm.user.email)) {
              _vm.allEmails.push(_vm.user.email);
            }
            if (!_vm.SPF.selectedEmails.includes(_vm.user.email)) {
              _vm.SPF.selectedEmails.push(_vm.user.email);
            }
          } else {
            _vm.allEmails = _vm.allEmails.filter(
              (email) => email !== _vm.user.email
            );
          }

          _vm.SPF.emails = _vm.SPF.self_send ? [_vm.user.email] : _vm.allEmails;
          // _vm.SPF.phones = _vm.allPhones;
          _vm.SPF = response.data.data;
          _vm.updateEmailList();
        });
    },
  },
  mounted() {
    let _vm = this;
    _vm.getDetail();
  },
};
