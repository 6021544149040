import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      SPF: {
        emails: [],
        self_send: false,
        tickets: {},
      },
      emailList: [],
      allEmails: [],
      loading: false,
    };
  },
  props: {
    sendPaymentForm: Boolean,
    sales: Array,
    showSendPaymentForm: Boolean,
    getSales: {
      type: Function,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    "SPF.self_send": {
      handler: "updateEmailList",
      immediate: true,
      deep: true,
    },
  },

  computed: {
    user() {
      return this.$store.state.userDetail;
    },
  },
  methods: {
    customLabel(option) {
      const [username, domain] = option.split("@");
      const maskedUsername = `${username.slice(0, 2)}${"*".repeat(
        username.length - 2
      )}${username.slice(-1)}`;
      return `${maskedUsername}@${domain}`;
    },
    formatTitle(title) {
      return title
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    validatePage() {
      this.loading = true;
      let _vm = this;
      const sale_reference_id = this.sales[0].sale_reference.id;
      console.log(sale_reference_id);
      if (this.SPF.id > 0) {
        this.axios
          .post("/send-ticket", {
            ...this.SPF,
            sale_reference_id,
            // _method: "PATCH",
          })
          .then(function () {
            _vm.loading = false;
            _vm.showSendTicketForm = false;
            // location.reload();
            _vm.getSales();
            _vm.$emit("updated");
          })
          .catch(function (error) { });
      } else {
        this.axios
          .post("/send-ticket", {
            ...this.SPF,
            sale_reference_id,
          })
          .then(function () {
            _vm.loading = false;
            _vm.showSendTicketForm = false;
            // location.reload();
            _vm.getSales();
            _vm.$emit("updated");
          })
          .catch(function (error) { });
      }
    },

    updateEmailList() {
      const userIndex = this.allEmails.indexOf(this.user.email);

      if (this.SPF.self_send) {
        if (userIndex === -1) {
          this.allEmails.push(this.user.email);
        }

        if (!this.SPF.selectedEmails) {
          this.$set(this.SPF, "selectedEmails", []);
        }

        if (this.SPF.selectedEmails.indexOf(this.user.email) === -1) {
          this.SPF.selectedEmails.push(this.user.email);
        }
      } else {
        if (userIndex !== -1) {
          this.$delete(this.allEmails, userIndex);
        }

        if (this.SPF.selectedEmails) {
          const userSelectedIndex = this.SPF.selectedEmails.indexOf(
            this.user.email
          );

          if (userSelectedIndex !== -1) {
            this.$delete(this.SPF.selectedEmails, userSelectedIndex);
          }
        }
      }
    },

    getDetail() {
      let _vm = this;
      const sale_reference_id = _vm.sales[0].sale_reference.id;
      this.axios
        .post("/send-ticket-preview/" + sale_reference_id)
        .then(function (response) {
          _vm.allEmails = response.data.data.emails.map((email) => `${email}`);

          if (_vm.SPF.self_send) {
            if (!_vm.allEmails.includes(_vm.user.email)) {
              _vm.allEmails.push(_vm.user.email);
            }
            if (!_vm.SPF.selectedEmails.includes(_vm.user.email)) {
              _vm.SPF.selectedEmails.push(_vm.user.email);
            }
          } else {
            _vm.allEmails = _vm.allEmails.filter(
              (email) => email !== _vm.user.email
            );
          }

          _vm.SPF.emails = _vm.SPF.self_send ? [_vm.user.email] : _vm.allEmails;
          _vm.SPF.tickets = response.data.data.tickets;
          _vm.updateEmailList();
        });
    },

    // getDetail() {
    //   let _vm = this;
    //   const sale_reference = this.sales[0].sale_reference.number;
    //   this.axios
    //     .post("/send-payment-form-preview/" + sale_reference)
    //     .then(function (response) {
    //       _vm.allEmails = response.data.data.emails.map((email) => `${email}`);
    //       if (_vm.SPF.self_send) {
    //         _vm.allEmails = _vm.allEmails.filter(
    //           (email) => email !== _vm.user.email
    //         );
    //       }
    //       _vm.SPF.emails = _vm.SPF.self_send ? [_vm.user.email] : _vm.allEmails;
    //       _vm.SPF = response.data.data;
    //       _vm.updateEmailList();
    //     });
    // },
  },
  mounted() {
    let _vm = this;
    _vm.getDetail();

    // _vm.$nextTick(() => {
    //   _vm.$refs.multiselect.model = _vm.SPF.selectedEmails;
    // });
  },
};
